import {
  SkillsGrid,
  SkillsGridProps,
  TCategory,
  TCollection,
  TSkill,
  TSkillVariant,
  TSkillWithOrg,
  TTeam,
  UNCATEGORISED_ID,
  useModalContext,
} from 'src/design-system'
import { useRailsContext } from 'components/rails-context'
import { SKILL_MODAL_ID, SkillModalProps } from 'components/skill-modal/utils'
import { SubscriptionState } from 'components/subscription-banner/subscription-banner'
import { TeamSecondaryNavUrls } from 'components/teams-secondary-nav'
import React from 'react'
import { CategoriesService } from '../services/categories-service'
import { trackEvent } from '../services/event-tracker'
import { SkillsService } from '../services/skills-service'
import { FrameworkState } from '../types/entities'
import { errorToast } from '../utils/error-toast'
import { openModal as openRailsModal } from '../utils/open-modal'
import { useApiClient } from '../utils/use-api-client'
import { useDrawerSkills } from '../utils/use-drawer-skills'
import { store } from 'store/index'

export type TeamSkillsPageProps = {
  skills?: TSkill[]
  skillVariants?: TSkillVariant[]
  categories: TCategory[]
  team: TTeam & { template?: boolean }
  frameworkId: number
  publicFrameworkUrl: string
  state: FrameworkState
  permissions: {
    allowEdit: boolean
    allowPublishTeam?: boolean
    allowViewSettings?: boolean
    allowAddSkills?: boolean
    allowViewPeople?: boolean
    allowViewReadme?: boolean
    allowViewOpenRoles?: boolean
    allowLockingCategories: boolean
  }
  orgAvatar?: string
  orgName: string
  orgSlug: string
  orgIsPublic: boolean
  readmeTitle: string
  urls: TeamSecondaryNavUrls
  subscriptionState: SubscriptionState
  trialEndsAt: number
}

export const TeamSkillsPage: React.VFC<TeamSkillsPageProps> = (
  initialProps
) => {
  const [props, setProps] = React.useState(initialProps)

  const { skills, skillVariants, frameworkId } = props
  const { org, request } = useRailsContext()
  const client = useApiClient()
  const service = React.useMemo(() => new CategoriesService(client), [client])
  const skillsService = React.useMemo(
    () => new SkillsService(client, org ?? undefined),
    [client, org]
  )

  const { openModal } = useModalContext()

  const onClickSkill = React.useCallback(
    (skill: TSkill, skillVariantId?: string) => {
      const category = props.categories.find((c) => c.id === skill.categoryId)

      openModal<SkillModalProps>(SKILL_MODAL_ID, {
        skillId: skill.id.toString(),
        frameworkId: frameworkId.toString(),
        orgId: org?.id?.toString(),
        showMakeACopy: false,
        source: 'team_skills_page',
        showRemoveFromTeam: !category?.locked || store.currentUser?.isAdmin,
        skillVariantId: skillVariantId,
      })
    },
    [frameworkId, openModal, org?.id, props.categories]
  )

  const onClickCollection = React.useCallback(
    (collection: TCollection) => {
      openRailsModal(
        `/collections/${collection.id}/view_modal?framework_id=${props.frameworkId}`
      )
    },
    [props.frameworkId]
  )

  const onReorderSkills = async (skillMap: Map<string | null, string[]>) => {
    try {
      await Promise.all(
        Array.from(skillMap).map(async ([categoryId, skillIds]) => {
          const id = categoryId === UNCATEGORISED_ID ? null : Number(categoryId)
          await service.updateSkills({
            frameworkId: props.frameworkId,
            categoryId: id,
            updatedSkillIds: skillIds,
          })
        })
      )
      refreshProps()
      return true
    } catch (e) {
      if (e instanceof Error) {
        errorToast(e.message)
      } else {
        errorToast('An unknown error occurred.')
      }
      return false
    }
  }

  const onReorderSkillVariants = async (
    skillVariantMap: Map<string | null, string[]>
  ) => {
    try {
      await Promise.all(
        Array.from(skillVariantMap).map(
          async ([categoryId, skillVariantIds]) => {
            const id =
              categoryId === UNCATEGORISED_ID ? null : Number(categoryId)
            await service.updateSkillVariants({
              frameworkId: props.frameworkId,
              categoryId: id,
              updatedSkillVariantIds: skillVariantIds,
            })
          }
        )
      )
      refreshProps()
      return true
    } catch (e) {
      errorToast()
      return false
    }
  }

  const onAddCategory = () => {
    openRailsModal(
      `/add_category_modal?framework_id=${props.frameworkId}&redirect_path=${location.pathname}`
    )
  }

  const refreshProps = React.useCallback(async () => {
    const data = await client.get<TeamSkillsPageProps>(location.pathname)
    setProps(data)
  }, [client])

  React.useEffect(() => {
    document.addEventListener('refreshTeamSkillsPage', refreshProps)

    return () => {
      document.removeEventListener('refreshTeamSkillsPage', refreshProps)
    }
  }, [refreshProps])

  const onEditCategory = async (
    category: TCategory,
    newTitle: string,
    newDescription?: string
  ) => {
    // TODO: better validation
    if (newTitle.length === 0) return 'Please provide a valid title.'
    if (newTitle.length >= 50) return 'Title can only be 50 characters.'
    await service.edit({
      frameworkId: props.frameworkId,
      category,
      newTitle,
      newDescription,
    })
    refreshProps()
  }

  const onDeleteCategory = async (category: TCategory) => {
    await service.destroy({
      frameworkId: props.frameworkId,
      category,
    })
    refreshProps()
  }

  const onReorderCategories = async (categoryIds: string[]) => {
    try {
      await service.sort(categoryIds)
      refreshProps()
      return true
    } catch (e) {
      console.error(e)
      errorToast()
      return false
    }
  }

  const {
    collections,
    createNewSkill,
    onOpen,
    onSearchChange,
    skills: drawerSkills,
    searchActive,
  } = useDrawerSkills('org')

  // Function used for optimistic UI update.
  // Displays newly added skill to the category before state is reloaded from server.
  const pushUpstreamSkill = React.useCallback(
    async (
      categoryId: string | number | null,
      skill: TSkill | TSkillWithOrg
    ) => {
      const oldSkills: TSkill[] = props.skills || []
      const newSkill: TSkill = {
        ...skill,
        categoryId: Number(categoryId) > 0 ? Number(categoryId) : null,
        sortIndex: oldSkills.length,
        clonedFromId: skill.cloneable ? skill.id : null,
        imageUrl: null,
      }
      const newSkills = [...oldSkills, newSkill]
      setProps({ ...props, skills: newSkills })
    },
    [props]
  )

  const onAddSkillToCategory = React.useCallback(
    async (
      skill: TSkill | TSkillWithOrg,
      categoryId: number | string,
      addType: 'drag' | 'click' = 'click'
    ) => {
      pushUpstreamSkill(categoryId, skill)

      const skillVariantsInCategory = skillVariants?.filter(
        (s) => s.categoryId?.toString() === categoryId.toString()
      )
      const sortedSkillVariants = skillVariantsInCategory?.sort(
        (a, b) => a.sortIndex - b.sortIndex
      )

      const sortedSkillVariantIds =
        sortedSkillVariants?.map((s) => s.id.toString()) || []

      const variant = skill.skillVariants && skill.skillVariants[0].id

      if (!variant) throw new Error('skillVariantId is required')

      const newSkillVariantIds = [...sortedSkillVariantIds, variant.toString()]

      if (parseInt(`${categoryId}`, 10) > 0) {
        await skillsService.addToTeam(
          variant.toString(),
          frameworkId,
          '',
          categoryId
        )
        await service.updateSkillVariants({
          frameworkId,
          categoryId: Number(categoryId),
          updatedSkillVariantIds: newSkillVariantIds,
        })
      } else {
        await skillsService.addToTeam(variant.toString(), frameworkId)
      }

      refreshProps()

      trackEvent('$track_add_skill_to_team', {
        source: 'skills-drawer',
        skillType: skill.cloneable ? 'library' : 'org',
        skillName: skill.name,
        categoryId: categoryId === UNCATEGORISED_ID ? null : categoryId,
        addType,
      })

      return true
    },
    [
      skills,
      frameworkId,
      service,
      skillsService,
      refreshProps,
      pushUpstreamSkill,
    ]
  )

  const onAddSkill = React.useCallback(
    async (
      skill: TSkill | TSkillWithOrg,
      category?: TCategory,
      skillVariantId?: string
    ) => {
      const skillAlreadyInTeam = !!props.skills?.find(
        (teamSkill) => teamSkill.id.toString() === skill.id.toString()
      )

      const categoryId = category?.id

      const variant =
        skillVariantId || (skill.skillVariants && skill.skillVariants[0]?.id)

      if (!variant) throw new Error('skillVariantId is required')

      await skillsService.addToTeam(
        variant.toString(),
        frameworkId,
        'skills-drawer',
        categoryId
      )

      trackEvent('$track_add_skill_to_team', {
        addType: 'click',
        categoryName: category?.name,
        skillName: skill.name,
        skillType: skill.cloneable ? 'library' : 'org',
        skillVariantId: variant,
        source: 'skills-drawer',
      })

      refreshProps()

      if (!skillAlreadyInTeam) {
        const uncategorisedColumnHeight = document.querySelector(
          '#uncategorisedColumn'
        )?.scrollHeight

        if (uncategorisedColumnHeight) {
          document.querySelector('#skillsGridContainer')?.scrollTo({
            behavior: 'smooth',
            left: 0,
            top: uncategorisedColumnHeight,
          })
        }
      }

      return true
    },
    [frameworkId, skillsService, refreshProps]
  )

  const onAddCollection = React.useCallback(
    async (
      collection: TCollection,
      categoryId?: number | string | null,
      addType: 'drag' | 'click' = 'click'
    ) => {
      await skillsService.addCollection(
        collection,
        frameworkId,
        'library',
        categoryId
      )
      refreshProps()

      trackEvent('$track_add_collection_to_team', {
        source: 'skills-drawer',
        collectionName: collection.name,
        addType,
      })
      return true
    },
    [frameworkId, skillsService, refreshProps]
  )

  const onAddCollectionFromDrawer = React.useCallback(
    async (collection: TCollection, categoryId?: string) => {
      onAddCollection(collection, categoryId)
    },
    [onAddCollection]
  )

  const onRemoveSkillFromTeam = async (skillToRemove: TSkill) => {
    setProps((current) => ({
      ...current,
      skills: current.skills?.filter((skill) => skill.id !== skillToRemove.id),
    }))
    await skillsService.removeFromTeam(
      skillToRemove,
      frameworkId,
      'skills-grid'
    )
    refreshProps()
  }

  const onRemoveSkillVariantFromTeam = async (
    skillVariantToRemove: TSkillVariant
  ) => {
    setProps((current) => ({
      ...current,
      skillVariants: current.skillVariants?.filter(
        (skillVariant) => skillVariant.id !== skillVariantToRemove.id
      ),
    }))
    await skillsService.removeVariantFromTeam(
      skillVariantToRemove,
      frameworkId,
      'skills-grid'
    )
    refreshProps()
  }

  const onCreateNewSkill = async (name: string, categoryId?: string) => {
    await createNewSkill({ name, frameworkId, categoryId })
    refreshProps()
  }

  const storeTeam = store.teams.byId(props.team.id.toString())

  const onToggleLockCategory = React.useCallback(
    async (category: TCategory) => {
      await service.toggleLock({
        frameworkId,
        category,
        inTemplateTeam: Boolean(storeTeam?.clonedFromTemplate),
      })
      refreshProps()
    },
    [service, frameworkId, refreshProps, storeTeam?.clonedFromTemplate]
  )

  const eventHandlers: SkillsGridProps['eventHandlers'] = {
    onAddCategory,
    onClickSkill,
    onDeleteCategory,
    onEditCategory,
    onRemoveSkillFromTeam,
    onRemoveSkillVariantFromTeam,
    onReorderCategories,
    onReorderSkills,
    onReorderSkillVariants,
    onAddSkillToCategory,
    onAddCollection,
    onToggleLockCategory,
  }

  const permissions: SkillsGridProps['permissions'] = {
    allowEdit: props.permissions.allowEdit,
    allowLockingCategories: props.permissions.allowLockingCategories,
  }

  const defaultOpen = request.query.open_drawer ? true : false

  const onClickCreateSkill = React.useCallback(() => {
    trackEvent('$track_skills_drawer_create_skill_click')
  }, [])

  const onClickGenerateSkill = React.useCallback(() => {
    trackEvent('$track_skills_drawer_generate_skill_click')
  }, [])

  const skillsDrawerProps: SkillsGridProps['skillsDrawerProps'] = {
    source: 'org',
    defaultOpen,
    collections: collections.collections,
    skills: drawerSkills.skills,
    showLoadingSpinner: drawerSkills.loading,
    onOpen: onOpen,
    eventHandlers: {
      onSearchChange,
      onAddSkill,
      onClickSkill,
      onClickCollection,
      onAddCollection: onAddCollectionFromDrawer,
      onCreateNewSkill,
      onClickCreateSkill,
      onClickGenerateSkill,
    },
    skillGeneratorUrl: '/skills/generate/new',
    showAiSkillGenerator: false,
    orgSkillsUrl: `${store.currentUser?.org?.skillsPath}?open_drawer=true`,
    showAddedSkills: searchActive,
  }

  return (
    <div className="app-screen justify-start h-full">
      <div
        id="skillsGridContainer"
        className="overflow-auto h-full bg-background"
      >
        <SkillsGrid
          categories={props.categories}
          eventHandlers={eventHandlers}
          permissions={permissions}
          skills={props.skills || []}
          skillVariants={props.skillVariants || []}
          skillsDrawerProps={skillsDrawerProps}
        />
      </div>
    </div>
  )
}
