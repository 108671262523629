import { TSkill, TCategory } from 'src/design-system'
import { trackEvent } from './event-tracker'
import { InternalApiClient } from './internal-api-client'

export class CategoriesService {
  constructor(private client: InternalApiClient) {}

  async destroy(props: {
    frameworkId: number
    category: TCategory
  }): Promise<void> {
    const { frameworkId, category } = props

    await this.client.delete(`/categories/${category.id}`, {
      framework_id: frameworkId,
    })
  }

  async edit(props: {
    frameworkId: number
    category: TCategory
    newTitle: string
    newDescription?: string
  }): Promise<void> {
    const { frameworkId, category, newTitle, newDescription } = props

    await this.client.put(`/categories/${category.id}`, {
      framework_id: frameworkId,
      name: newTitle,
      description: newDescription,
    })
  }

  async toggleLock(props: {
    frameworkId: number
    category: TCategory
    inTemplateTeam: boolean
  }): Promise<void> {
    const { frameworkId, category, inTemplateTeam } = props

    await this.client.put(`/categories/${category.id}`, {
      framework_id: frameworkId,
      locked: !category.locked,
    })

    trackEvent('$toggle_lock_category', {
      categoryName: category.name,
      locked: !category.locked,
      inTemplateTeam,
    })
  }

  // Does not work.
  async updateSkills(props: {
    frameworkId: number
    categoryId: number | null
    updatedSkillIds: string[]
  }): Promise<void> {
    const { frameworkId, categoryId, updatedSkillIds } = props

    await this.client.patch(`/category_skills/sort`, {
      framework_id: frameworkId,
      category_id: categoryId,
      skill: updatedSkillIds,
    })
  }

  async updateSkillVariants(props: {
    frameworkId: number
    categoryId: number | null
    updatedSkillVariantIds: string[]
  }): Promise<void> {
    const { frameworkId, categoryId, updatedSkillVariantIds } = props

    await this.client.patch(`/category_skills/sort`, {
      framework_id: frameworkId,
      category_id: categoryId,
      updatedSkillVariantIds: updatedSkillVariantIds,
    })
  }

  async sort(categoryIds: string[]): Promise<void> {
    await this.client.patch('/categories/sort', {
      category: categoryIds,
    })
  }
}
